<template>
  <div>
    <a-modal
      width="520px"
      :visible="visible"
      :title="type ? '解绑微信' : '绑定微信'"
      @ok="handleOk"
      @cancel="closeModal"
      :okButtonProps="type || { style: { display: 'none' } }"
      :okText="type ? '确认解绑' : '确认'"
      :cancelText="type ? '暂不解绑' : '取消'"
    >
      <div class="unbind-row" v-if="type">
        <span class="unbind-text">
          解绑微信后你将无法使用微信登录人脉宝盒，你确定要解绑吗?
        </span>
      </div>
      <div class="bind-row" v-else>
        <span class="bind-text"> 使用微信扫一扫绑定 「人脉宝盒 」 </span>
        <div class="code-contain">
          <div id="codeArea"></div>
          <div class="expire-mark" v-show="isExpire">
            <span class="expire-tip">二维码已过期</span>
            <div class="expire-btn" @click="getWxQrcode()">
              <span>刷新</span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, watch } from 'vue';
import { Modal as AModal, message } from 'ant-design-vue';
import { useStore } from 'vuex';
import loginApi from '@/api/login';

const store = useStore();

const props = defineProps({ visible: Boolean, type: Number });
const emits = defineEmits(['fnOk']);

const isExpire = ref(false);
const expireTimer = ref(null);

const closeModal = () => {
  emits('fnOk', false);
};

const handleOk = () => {
  if (props.type) {
    loginApi
      .UnbindWeChat('', {
        userId: store.state.account.user.id,
      })
      .then(res => {
        if (res) {
          message.success('解绑成功');
          emits('fnOk', false);
        }
      });
  }
};

const getWxQrcode = () => {
  console.log(process.env.VUE_APP_QRCODE_ADDRESS);
  clearTimeout(expireTimer.value);
  isExpire.value = false;
  localStorage.setItem('ctime', new Date().getTime());
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
  const wxElement = document.body.appendChild(s);
  wxElement.onload = function () {
    // eslint-disable-next-line no-undef
    var obj = new WxLogin({
      id: 'codeArea', // 需要显示的容器id
      appid: 'wx1a4ffc2f87b353f5', // 公众号appid wx*******
      scope: 'snsapi_login', // 网页默认即可
      redirect_uri: encodeURIComponent(
        process.env.VUE_APP_QRCODE_ADDRESS + '/#/bindGetCode'
      ), // 授权成功后回调的url
      state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
      style: 'white', // 提供"black"、"white"可选。二维码的样式
      href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEwOHB4O21hcmdpbi10b3A6MDt9Ci5pbXBvd2VyQm94IHt3aWR0aDoxMDhweDtsaW5lLWhlaWdodDowO30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAxMDhweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30gCi53YWl0aW5ne3Bvc2l0aW9uOnJlYWx0aXZlfQouc3RhdHVzX3N1Y2N7d2lkdGg6MTAwJTtoZWlnaHQ6MTAwJTtwYWRkaW5nOjAgIWltcG9ydGFudDtkaXNwbGF5OmZsZXg7YWxpZ24taXRlbXM6Y2VudGVyO2p1c3RpZnktY29udGVudDogY2VudGVyO3Bvc2l0aW9uOmFic29sdXRlO3RvcDo1MCU7bGVmdDo1MCU7dHJhbnNmb3JtOnRyYW5zbGF0ZSgtNTAlLC01MCUpO2JhY2tncm91bmQ6IHJnYmEoMCwgMCwgMCwgMC42KTt9Ci5pbXBvd2VyQm94IC5zdGF0dXNfdHh0e2Rpc3BsYXk6bm9uZX0=', // 外部css文件url，需要https
    });
  };
  expireTimer.value = setTimeout(() => {
    isExpire.value = true;
  }, 120000);
};

watch(
  () => props.visible,
  (newVal, oldVal) => {
    if (newVal) {
      if (!props.type) {
        getWxQrcode();
      }
    }
  }
);
</script>

<style lang="less" scoped>
.unbind-row,
.bind-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0 40px;
  box-sizing: border-box;
}

.unbind-text {
  width: 360px;
  margin: 0 auto;
  display: inline-block;
  color: #3d3d3d;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.bind-text {
  width: 144px;
  margin: 0 auto;
  display: inline-block;
  color: #3d3d3d;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.code-contain {
  width: 116px;
  height: 116px;
  margin: 15px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: url('../../../assets/images/login/code-contain-back.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;

  .expire-mark {
    width: 112px;
    height: 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    gap: 10px;

    .expire-tip {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }

    .expire-btn {
      padding: 8px 32px;
      box-sizing: border-box;
      border: 1px solid #ffffff;
      border-radius: 5px;
      cursor: pointer;

      span {
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  #codeArea {
    width: 112px;
    height: 112px;
  }
}
</style>
